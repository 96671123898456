import { ACTIONS } from '@/constants/vuex'
import {
  createHoliday,
  deleteHoliday,
  listHolidays,
  updateHoliday
} from '@/services/holidays'

export default {
  [ACTIONS.HOLIDAYS.GET_LIST]: async ({ rootGetters }, payload) => {
    let { locationIds, ...rest } = payload
    locationIds = locationIds?.join(',')
    const holidays = await listHolidays({ locationIds, ...rest })

    return holidays
  },
  [ACTIONS.HOLIDAYS.STORE]: async ({ rootGetters }, payload) => {
    const holiday = await createHoliday(payload)

    return holiday
  },
  [ACTIONS.HOLIDAYS.DELETE]: async ({ rootGetters }, payload) => {
    const holiday = await deleteHoliday(payload)

    return holiday
  },
  [ACTIONS.HOLIDAYS.UPDATE]: async ({ rootGetters }, payload) => {
    const holiday = await updateHoliday(payload)

    return holiday
  }
}
