import axios from '@/lib/axios'

const URL = 'dimensions'

export const listDimensions = async () => {
  const { data } = await axios.get(URL, {
    params: {
      pagesize: Number.MAX_SAFE_INTEGER
    }
  })

  return data
}
