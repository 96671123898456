import Vue from 'vue'
import Vuex from 'vuex'
import parameter from '@/store/parameter'
import appShell from '@/store/appShell'
import accountsLocations from '@/store/accountsLocations'
import holiday from '@/store/holiday'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    appShell,
    parameter,
    accountsLocations,
    holiday
  }
})
