const getUrl = (path) => {
  if (process.NODE_ENV === 'development') {
    return process.env.VUE_APP_API_BASE_URL + path
  }
  return window.parent?.IFC_VARIABLES?.baseURL + path
}

const getService = () => {
  return {
    VUE_APP_BASE_URL: getUrl(process.env.VUE_APP_API_LOGISTIC_ADMIN_PATH)
  }
}

export default getService
