import App from '@/App.vue'
import MixinDebounce from '@/mixins/debounce'
import plugins from '@/plugins'
import router from '@/router'
import store from '@/store'
import auth from '@/utils/app-shell-auth'
import singleSpaVue from 'single-spa-vue'
import Vue from 'vue'

Vue.config.productionTip = false
Vue.mixin({
  methods: {
    ...MixinDebounce.methods
  }
})

var postMessageTemp = window.parent.postMessage
window.parent.postMessage = function (message, targetOrigin, transfer) {
  postMessageTemp(JSON.parse(JSON.stringify(message)), targetOrigin, transfer)
}

const requireMixin = require.context('./mixins', false, /\w+\.(js)$/)

requireMixin.keys().forEach((fileName) => {
  const mixinFile = requireMixin(fileName)
  if (typeof mixinFile.default === 'function') mixinFile.default(Vue)
})

const requireFilter = require.context('./filters', false, /\w+\.(js)$/)

requireFilter.keys().forEach((fileName) => {
  const filterFile = requireFilter(fileName)
  if (typeof filterFile.default === 'function') filterFile.default(Vue)
})

const options = { router, store, ...plugins }

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    mixins: [options],
    render(h) {
      return h(App, {
        props: {
          tokenIdSubject: this.tokenIdSubject,
          basepath: this.basepath,
          location: this.location
        }
      })
    },
    el: '#mfe_div_connector'
  },
  handleInstance(app) {
    auth.listen(app.tokenIdSubject, app.$root.$emit, app.$root)
  }
})
export const bootstrap = (props) => {
  return Promise.resolve().then(() => {
    vueLifecycles.bootstrap(props)
  })
}

export const mount = (props) => {
  return Promise.resolve().then(() => {
    vueLifecycles.mount(props)
  })
}

export const unmount = (props) => {
  return Promise.resolve().then(() => {
    vueLifecycles.unmount(props)
  })
}

export const withoutSingleSpa = ({ elementDOM, basePath, location }) => {
  const options = {
    store,
    ...plugins
  }

  window.app = new Vue({
    mixins: [
      {
        router: router,
        mounted: function () {
          if (location) {
            router.push(location)
          }
        },
        ...options,
        computed: {
          $auth: () => auth
        }
      }
    ],
    render(h) {
      return h(App, {})
    }
  })

  auth.listen(
    Vue.observable({ token: window.parent.IFC_VARIABLES.auth.token }),
    window.app.$root.$emit,
    window.app.$root
  )

  return window.app.$mount(elementDOM)
}
