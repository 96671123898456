import axios from '@/lib/axios'
import { formatParams } from './formatParams'

const URL = 'holidays'

export const listHolidays = async (params) => {
  const formattedParams = formatParams(params, [
    'page|page',
    'itemsPerPage|pagesize',
    'sortBy|sortby',
    'name|name',
    'locationIds|locationIds'
  ])

  const { data } = await axios.get(URL, {
    params: formattedParams
  })

  return data
}

export const createHoliday = async (payload) => {
  const { data } = await axios.post(URL, payload)

  return data
}

export const deleteHoliday = async ({ holidayId }) => {
  if (!holidayId) throw new Error('Holiday id is required')

  const { data } = await axios.delete(`${URL}/${holidayId}`)

  return data
}

export const updateHoliday = async (payload) => {
  if (!payload.id) throw new Error('Holiday id is required')

  const { data } = await axios.put(`${URL}/${payload.id}`, payload)

  return data
}
