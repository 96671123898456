import axios from 'axios'
import store from '@/store'
import { GETTERS } from '@/constants/vuex'
import { i18n } from '@/lib/i18n'
import getService from '@/utils/get-service'
import { IS_DEV } from '@/constants/enviroments'

const instance = axios.create({
  baseURL: getService().VUE_APP_BASE_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
  }
})

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { response } = error || {}
    const { data = {} } = response || {}
    let { code, detail } = data?.errors?.[0] || {}
    code = `error.${code?.replace('/', '.')}`

    const translatedMessage = i18n.t(code)
    const message = translatedMessage === code ? detail : translatedMessage
    return Promise.reject(message || i18n.t('error.generic'))
  }
)

instance.interceptors.request.use(
  (config) => {
    const xTenantId = IS_DEV
      ? store.getters[`appShell/${GETTERS.APP_SHELL.GET_ACCOUNT_ID}`]
      : window.parent.IFC_VARIABLES.headers['x-tenant-id']

    config.headers['x-tenant-id'] = xTenantId
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export default instance
