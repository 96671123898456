import LoggedLayout from '@/layout/Logged'
import PRIORITIZATION from '@/router/routes/prioritization'
import PRIORITIZATION_CONFIG from '@/router/routes/prioritizationConfig'
import LOCATION from '@/router/routes/location'
import HOLIDAY from '@/router/routes/holiday'
import GENERAL_SETTINGS from '@/router/routes/generalSettings'
import RULES from '@/router/routes/rules'
import RULE from '@/router/routes/rule'

export const BASE_PATH = '/'
export default [
  {
    path: BASE_PATH,
    component: LoggedLayout,
    children: [
      PRIORITIZATION,
      PRIORITIZATION_CONFIG,
      LOCATION,
      HOLIDAY,
      GENERAL_SETTINGS,
      RULES,
      RULE
    ]
  }
]
