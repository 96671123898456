export const formatParams = (params, keys) => {
  const newParams = {}

  keys.forEach((key) => {
    const [valueKey, paramKey] = key.split('|')
    const value = params[valueKey]
    if (valueKey === 'page' || (valueKey === 'itemsPerPage' && value)) {
      newParams[paramKey] =
        valueKey === 'page' ? Number(value) - 1 : Number(value)
      return
    }
    if (paramKey === 'sortby') {
      const { sortBy, sortDesc } = params
      if (sortBy?.length) {
        const [sortByKey] = sortBy
        const [sortDirection] = sortDesc
        newParams.sortby = sortByKey
        newParams.orderdirection = sortDirection ? 'desc' : 'asc'
      }
    }

    if (value && !Array.isArray(value)) newParams[paramKey] = value
  })

  return newParams
}
