<template>
  <ifc-main :isDev="isDev" data-app>
    <router-view />
  </ifc-main>
</template>

<script>
import { mapMutations } from 'vuex'
import { IS_DEV } from './constants/enviroments'
import { MUTATIONS } from './constants/vuex'
import router from './router'

export default {
  name: 'App',
  props: ['tokenIdSubject', 'basePath', 'location'],
  data: () => ({
    subscription: {},
    newRoute: ''
  }),
  computed: {
    isDev() {
      return IS_DEV
    }
  },
  destroyed() {
    this.subscription?.unsubscribe?.()
  },
  methods: {
    ...mapMutations({
      setStore: `appShell/${MUTATIONS.APP_SHELL.SET_STORE}`
    }),
    handleSubscribe({ store }) {
      this.setStore(store)
    },
    handleRouteChange(event) {
      router.push(event.detail.newRoute)
    }
  },
  watch: {
    $route: function ({ path, fullPath, query }) {
      window.parent.postMessage({ path, fullPath, query }, '*')
    },
    tokenIdSubject: {
      immediate: true,
      handler(value) {
        if (!value) return
        this.subscription = value.subscribe(this.handleSubscribe)
      }
    }
  },
  created() {
    this.newRoute = this.location.includes('old-app')
      ? this.location.replace(
          `/old-app/${window.parent.IFC_VARIABLES.headers['x-tenant-id']}/logistic`,
          ''
        )
      : this.$route.fullPath
    router.push(this.newRoute)
  },
  mounted() {
    window.addEventListener('changeRoute', this.handleRouteChange)

    // Make sure to remove the event listener when the component is destroyed
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('changeRoute', this.handleRouteChange)
    })
  }
}
</script>
<style lang="scss">
.v-application {
  background: #fafafc !important;
}

.ifc-plugins-notify-text {
  white-space: pre-line;
}
</style>
