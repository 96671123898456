let timer

export default {
  methods: {
    $debounce(func, timeout = 300) {
      clearTimeout(timer)
      timer = setTimeout(func, timeout)
    }
  }
}
