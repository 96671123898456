import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ptBR from '@/lang/pt-BR'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'pt-BR',
  fallbackLocale: 'pt-BR',
  messages: {
    'pt-BR': ptBR
  }
})
