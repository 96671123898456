import { GETTERS } from '@/constants/vuex'
import { IS_DEV } from '@/constants/enviroments'

export default {
  [GETTERS.APP_SHELL.GET_ACCOUNT_ID]: (state, payload) => {
    const accountId = IS_DEV
      ? state?.store?.id
      : window.parent.IFC_VARIABLES.headers['x-tenant-id']

    return accountId
  }
}
