/**
 * Helper AppShell auth object
 */

const NODE_ENV = process.env.NODE_ENV
const IS_DEV = NODE_ENV === 'development'
const DEFAULT_AUTH = {
  authToken: window.parent.IFC_VARIABLES.auth.token
}
const jwtDecode = (token) => {
  try {
    return JSON.parse(window.atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

function convertListRoles(rolesAccount) {
  const authorized = !!rolesAccount?.length
  const roles = rolesAccount?.reduce((a, v) => ({ ...a, [v]: true }), {}) ?? {}

  return { ...roles, authorized }
}

const rolesDevelopment = {
  'cxaas-logistic': {
    roles: ['admin', 'enduser', 'keyaccount']
  }
}

function AppShellAuth() {
  this.listen = (tokenObservable, eventBus, ctx) => {
    if (!tokenObservable) return

    if (IS_DEV) {
      const tokenDecode = jwtDecode(DEFAULT_AUTH.authToken)
      const roles = convertListRoles(
        rolesDevelopment?.['cxaas-logistic']?.roles
      )

      this.token = DEFAULT_AUTH.authToken
      this.seller.id = DEFAULT_AUTH.sellerId
      this.account = DEFAULT_AUTH.store
      this.tenantId = DEFAULT_AUTH?.store?.id
      this.sessionId = tokenDecode?.session_state ?? ''
      this.userRoles = roles

      eventBus.apply(ctx, [this.event + 'update', this])
      return
    }

    if (DEFAULT_AUTH.authToken !== '') {
      const tokenDecode = jwtDecode(DEFAULT_AUTH.authToken)
      const rolesAccount =
        tokenDecode?.resource_access?.['cxaas-logistic']?.roles

      const roles = convertListRoles(rolesAccount)

      this.token = DEFAULT_AUTH.authToken
      this.account = {
        id: window.parent.IFC_VARIABLES.headers['x-tenant-id'],
        name: 'lorem',
        slug: 'lorem'
      }
      this.tenantId = window.parent.IFC_VARIABLES.headers['x-tenant-id']
      this.sessionId = tokenDecode?.session_state ?? ''
      this.userRoles = roles
    }
  }
  this.unsubscribe = (tokenObservable) => {
    return tokenObservable?.unsubscribe()
  }
}
function AppShellAuthObject(data) {
  this.id = data.id
}
AppShellAuth.prototype.event = 'appshell:auth:'
AppShellAuth.prototype.token = null
AppShellAuth.prototype.tenantId = null
AppShellAuth.prototype.sessionId = null
AppShellAuth.prototype.seller = new AppShellAuthObject({})
AppShellAuth.prototype.account = new AppShellAuthObject({})
AppShellAuth.prototype.userRoles = []

// single instance
export default new AppShellAuth()
