export const URL_RULE_PARAM_ID = 'ruleId'
export const PATH_RULE = `/rule/:${URL_RULE_PARAM_ID}?`
export const PATH_RULES = `/rules`
export const URL_RULE_NAME = 'rule'

export default {
  path: PATH_RULE,
  name: URL_RULE_NAME,
  component: () => import('@/views/Rule.vue'),
  meta: {
    title: 'Nova Regra'
  }
}
