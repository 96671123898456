import axios from '@/lib/axios'

const URL = 'accounts/location/:locationId/working-days-settings'

const getUrl = (locationId) => {
  if (!locationId) return console.error('Account id is required')
  return URL.replace(':locationId', locationId)
}

export const updateWorkingTimes = async ({
  locationId,
  workingDaysSettings
}) => {
  const url = getUrl(locationId)
  const { data } = await axios.put(url, {
    workingDaysSettings
  })

  return data
}
