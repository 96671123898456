export const PATH_LOCATION = '/holiday'

export default {
  path: PATH_LOCATION,
  name: 'Holiday',
  component: () => import('@/views/Holiday.vue'),
  meta: {
    title: 'Cadastro de Feriados'
  }
}
