import { MUTATIONS } from '@/constants/vuex'
import dayjs from '@/lib/dayjs'
import groupBy from 'lodash.groupby'
import clonedeep from 'lodash.clonedeep'

export default {
  [MUTATIONS.ACCOUNTS_LOCATIONS.SET_LOCATIONS]: (state, payload = []) => {
    state.locations = [...payload]
  },
  [MUTATIONS.ACCOUNTS_LOCATIONS.REFRESH_LOCATION]: (state, payload) => {
    const locations = state.locations

    const indexLocation = locations.findIndex(
      (location) => location.id === payload.id
    )
    locations.splice(indexLocation, 1, payload)

    state.locations = [...locations]
  },
  [MUTATIONS.ACCOUNTS_LOCATIONS.SET_SELECT_LOCATION]: (state, payload) => {
    if (!payload) {
      state.selectedLocation = undefined
      state.allWeekDays = []
      state.openHoursAllWeekDays = []
      return
    }

    const openHoursAllWeekDays = new Set()
    const openingHours = Object.values(
      groupBy(
        payload?.businessHourSettings?.openingHours
          .sort((prev, next) => (prev.dayOfWeek < next.dayOfWeek ? 1 : -1))
          .map(({ startTime, endTime, dayOfWeek, ...rest }) => {
            openHoursAllWeekDays.add(dayOfWeek)
            return {
              ...rest,
              startTime,
              endTime,
              dayOfWeek,
              range: `${startTime} - ${endTime}`
            }
          }) || [],
        'range'
      )
    )

    const openingHoursFormatted = openingHours.map((openingHour) =>
      openingHour.reduce(
        (current, next) => {
          const daysOfWeek = [...current.daysOfWeek, next.dayOfWeek]
          const { dayOfWeek, startTime, endTime, ...restNext } = next
          const translatedDayOfWeek = dayjs().day(next.dayOfWeek).format('dddd')
          const hasOnlyOnePosition = openingHour.length === 1
          let labelDaysOfWeek = `${
            hasOnlyOnePosition ? '' : 'e '
          }${translatedDayOfWeek}`
          if (current.labelDaysOfWeek) {
            labelDaysOfWeek = `${translatedDayOfWeek}, ${current.labelDaysOfWeek}`
          }

          labelDaysOfWeek = labelDaysOfWeek.replace(', e ', ' e ')
          const labelPeriod = `${startTime.substring(0, 5)}-${endTime.substring(
            0,
            5
          )}`

          return {
            ...restNext,
            daysOfWeek,
            startTime,
            endTime,
            labelPeriod,
            labelDaysOfWeek
          }
        },
        {
          daysOfWeek: []
        }
      )
    )

    const carrierBusinessHours = Object.values(
      groupBy(
        payload?.businessHourSettings?.carrierBusinessHours || [],
        'closingTime'
      )
    )

    const allWeekDays = new Set()
    const carrierBusinessHoursFormatted = carrierBusinessHours.map((detail) =>
      detail
        .sort((prev, next) => (prev.dayOfWeek < next.dayOfWeek ? 1 : -1))
        .reduce(
          (current, next) => {
            const daysOfWeek = [...current.daysOfWeek, next.dayOfWeek]
            const translatedDayOfWeek = dayjs()
              .day(next.dayOfWeek)
              .format('dddd')
            const hasOnlyOnePosition = detail.length === 1
            let labelDaysOfWeek = `${
              hasOnlyOnePosition ? '' : 'e '
            }${translatedDayOfWeek}`
            if (current.labelDaysOfWeek) {
              labelDaysOfWeek = `${translatedDayOfWeek}, ${current.labelDaysOfWeek}`
            }

            labelDaysOfWeek = labelDaysOfWeek.replace(', e ', ' e ')
            allWeekDays.add(next.dayOfWeek)
            return {
              saved: true,
              closingTime: next.closingTime,
              daysOfWeek,
              labelDaysOfWeek
            }
          },
          {
            closingTime: undefined,
            labelDaysOfWeek: undefined,
            daysOfWeek: [],
            alreadySaved: true
          }
        )
    )

    const selectedLocation = {
      ...payload,
      businessHourSettings: {
        openingHours: openingHoursFormatted,
        carrierBusinessHours: carrierBusinessHoursFormatted
      }
    }

    state.selectedLocation = clonedeep(selectedLocation)
    state.allWeekDays = [...allWeekDays]
    state.openHoursAllWeekDays = [...openHoursAllWeekDays]
  },
  [MUTATIONS.ACCOUNTS_LOCATIONS.ADD_CARRIER_BUSINESS_HOURS]: (state) => {
    const selectedLocation = clonedeep(state.selectedLocation)

    selectedLocation.businessHourSettings.carrierBusinessHours.push({
      closingTime: undefined,
      daysOfWeek: undefined,
      labelDaysOfWeek: undefined
    })

    state.selectedLocation = selectedLocation
  },
  [MUTATIONS.ACCOUNTS_LOCATIONS.ADD_OPEN_HOURS]: (state) => {
    const selectedLocation = clonedeep(state.selectedLocation)

    selectedLocation.businessHourSettings.openingHours.push({
      startTime: undefined,
      endTime: undefined,
      daysOfWeek: undefined
    })

    state.selectedLocation = selectedLocation
  },
  [MUTATIONS.ACCOUNTS_LOCATIONS.DELETE_LOCATION_WEEKDAY]: (state, weekday) => {
    const oldAllWeekDays = new Set([...state.allWeekDays])
    oldAllWeekDays.delete(weekday)

    state.allWeekDays = [...oldAllWeekDays.values()]
  },
  [MUTATIONS.ACCOUNTS_LOCATIONS.ADD_LOCATION_WEEKDAY]: (state, weekday) => {
    const oldAllWeekDays = new Set([...state.allWeekDays])
    oldAllWeekDays.add(weekday)

    state.allWeekDays = [...oldAllWeekDays.values()]
  },
  [MUTATIONS.ACCOUNTS_LOCATIONS.DELETE_LOCATION_WEEKDAY_OPEN_HOURS]: (
    state,
    weekday
  ) => {
    const oldAllWeekDays = new Set([...state.openHoursAllWeekDays])
    oldAllWeekDays.delete(weekday)

    state.openHoursAllWeekDays = [...oldAllWeekDays.values()]
  },
  [MUTATIONS.ACCOUNTS_LOCATIONS.ADD_LOCATION_WEEKDAY_OPEN_HOURS]: (
    state,
    weekday
  ) => {
    const oldAllWeekDays = new Set([...state.openHoursAllWeekDays])
    oldAllWeekDays.add(weekday)

    state.openHoursAllWeekDays = [...oldAllWeekDays.values()]
  }
}
