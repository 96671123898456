export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    $open: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
