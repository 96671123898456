import { IS_DEV } from '@/constants/enviroments'

const storeDevelopment = {
  id: '61a9fbac5d4828001172df30',
  name: 'lorem',
  slug: 'lorem'
}

export const DEFAULT_STATE = {
  store: IS_DEV
    ? storeDevelopment
    : {
        id: undefined,
        name: '',
        slug: ''
      }
}

export default DEFAULT_STATE
