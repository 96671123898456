import dayjs from '@/lib/dayjs'

export default (Vue) => {
  Vue.filter('datetime', (value) => {
    const date = dayjs(value)
    const isValid = date.isValid()
    if (!isValid) return ''
    return date.format('DD/MM/YYYY HH:mm:ss')
  })
}
