export const PATH_LOCATION = '/general-settings'

export default {
  path: PATH_LOCATION,
  name: 'General Configs',
  component: () => import('@/views/GeneralSettings.vue'),
  meta: {
    title: 'Configurações gerais'
  }
}
