export const PATH_RULES = '/rules'

export default {
  path: PATH_RULES,
  name: 'Rules',
  component: () => import('@/views/Rules.vue'),
  meta: {
    title: 'Regras de Negócio'
  }
}
