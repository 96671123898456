import { ACTIONS, MUTATIONS } from '@/constants/vuex'
import { listDimensions } from '@/services/dimensions'

export default {
  [ACTIONS.PARAMETER.GET_DIMENSIONS]: async ({ commit, state }) => {
    if (state.dimensions.length) return
    const dimensions = await listDimensions()
    commit(MUTATIONS.PARAMETER.SET_DIMENSIONS, dimensions)
  }
}
