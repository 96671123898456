export const MUTATIONS = {
  PARAMETER: {
    SET_DIMENSIONS: 'SET_DIMENSIONS'
  },
  NOTIFY: {
    SET_NOTIFY: 'SET_NOTIFY'
  },
  APP_SHELL: {
    SET_STORE: 'SET_STORE'
  },
  ACCOUNTS_LOCATIONS: {
    SET_LOCATIONS: 'SET_LOCATIONS',
    REFRESH_LOCATION: 'REFRESH_LOCATION',
    SET_SELECT_LOCATION: 'SET_SELECT_LOCATION',
    ADD_OPEN_HOURS: 'ADD_OPEN_HOURS',
    ADD_CARRIER_BUSINESS_HOURS: 'ADD_CARRIER_BUSINESS_HOURS',
    SET_LOCATION_WEEKDAYS: 'SET_LOCATION_WEEKDAYS',
    DELETE_LOCATION_WEEKDAY: 'DELETE_LOCATION_WEEKDAY',
    ADD_LOCATION_WEEKDAY: 'ADD_LOCATION_WEEKDAY',
    DELETE_LOCATION_WEEKDAY_OPEN_HOURS: 'DELETE_LOCATION_WEEKDAY_OPEN_HOURS',
    ADD_LOCATION_WEEKDAY_OPEN_HOURS: 'ADD_LOCATION_WEEKDAY_OPEN_HOURS'
  }
}

export const GETTERS = {
  APP_SHELL: {
    GET_ACCOUNT_ID: 'GET_ACCOUNT_ID'
  }
}

export const ACTIONS = {
  PARAMETER: {
    GET_DIMENSIONS: 'GET_DIMENSIONS'
  },
  ACCOUNTS_LOCATIONS: {
    GET_LIST: 'GET_LIST_ACCOUNTS_LOCATIONS',
    UPDATE_OPENING_HOURS: 'UPDATE_OPENING_HOURS',
    UPDATE_SEPARATION_TIME: 'UPDATE_SEPARATION_TIME',
    UPDATE_STOCK_TIME: 'UPDATE_STOCK_TIME',
    UPDATE_CARRIER_BUSINESS_HOURS: 'UPDATE_CARRIER_BUSINESS_HOURS',
    UPDATE_CLOSING_TIME: 'UPDATE_CLOSING_TIME',
    UPDATE_WORKING_DAYS_SETTINGS: 'UPDATE_WORKING_DAYS_SETTINGS'
  },
  HOLIDAYS: {
    GET_LIST: 'GET_LIST_HOLIDAYS',
    STORE: 'STORE_HOLIDAY',
    DELETE: 'DELETE_HOLIDAY',
    UPDATE: 'UPDATE_HOLIDAY'
  }
}
