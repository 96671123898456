import axios from '@/lib/axios'

const URL = 'accounts/location/:locationId/closing-time'

const getUrl = (locationId) => {
  if (!locationId) return console.error('Account id is required')
  return URL.replace(':locationId', locationId)
}

export const updateClosingTime = async ({
  locationId,
  carrierBusinessHours
}) => {
  const url = getUrl(locationId)
  const { data } = await axios.put(url, {
    businessHourSettings: {
      carrierBusinessHours
    }
  })

  return data
}
