import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import LocaleData from 'dayjs/plugin/localeData'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import Utc from 'dayjs/plugin/utc'
import Timezone from 'dayjs/plugin/timezone'

dayjs.locale('pt-br')

dayjs.extend(LocaleData)
dayjs.extend(LocalizedFormat)
dayjs.extend(Utc)
dayjs.extend(Timezone)

export default dayjs
