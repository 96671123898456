import dayjs from '@/lib/dayjs'

export default (Vue) => {
  Vue.filter('date', (value) => {
    const date = dayjs.utc(value).tz('America/Sao_Paulo')
    const isValid = date.isValid()
    if (!isValid) return ''
    return date.format('L')
  })
}
