export const PATH_PRIORITIZATION = '/prioritization'

export default {
  path: PATH_PRIORITIZATION,
  name: 'Prioritization',
  component: () => import('@/views/Prioritization.vue'),
  meta: {
    title: 'Regras de Priorização'
  }
}
