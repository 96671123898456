export const PATH_LOCATION = '/location'

export default {
  path: PATH_LOCATION,
  name: 'Location',
  component: () => import('@/views/Location.vue'),
  meta: {
    title: 'Cadastro de Funcionamento'
  }
}
