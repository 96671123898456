const PARAM_ID = `:id`
export const PATH_PRIORITIZATION_CONFIG = `/prioritization/${PARAM_ID}/config`

export const getUrlPriorizationConfig = (id) => {
  return PATH_PRIORITIZATION_CONFIG.replace(PARAM_ID, id)
}

export default {
  path: PATH_PRIORITIZATION_CONFIG,
  name: 'Prioritization Config',
  component: () => import('@/views/PrioritizationConfig.vue'),
  meta: {
    title: 'Configuração de priorização'
  }
}
