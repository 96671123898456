import actions from '@/store/accountsLocations/actions'
import mutations from '@/store/accountsLocations/mutations'
import state from '@/store/accountsLocations/state'

export default {
  namespaced: true,
  actions,
  mutations,
  state
}
