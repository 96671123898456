import { MUTATIONS } from '@/constants/vuex'
import { i18n } from '@/lib/i18n'

export default {
  [MUTATIONS.PARAMETER.SET_DIMENSIONS]: (state, payload) => {
    const dimensions = []
    const dimensionsDescription = {}

    payload.forEach(({ name, slug, impact, description }) => {
      dimensionsDescription[slug] = description
      dimensions.push({
        name,
        slug,
        impact: impact.map((value) => ({
          label: i18n.t(`impact.${value}`),
          value
        }))
      })
    })

    state.dimensions = dimensions
    state.dimensionsDescription = dimensionsDescription
  }
}
