import beforeEach from '@/router/guard/beforeEach'
import routes from '@/router/routes'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  base: '',
  mode: 'hash',
  routes: [...routes]
})

beforeEach(router)

export default router
