import { GETTERS } from '@/constants/vuex'
import axios from '@/lib/axios'
import store from '@/store'
import { IS_DEV } from '@/constants/enviroments'

const URL = 'accounts/:accountId/locations'

const getUrl = () => {
  const accountId = IS_DEV
    ? store.getters[`appShell/${GETTERS.APP_SHELL.GET_ACCOUNT_ID}`]
    : window.parent.IFC_VARIABLES.headers['x-tenant-id']

  return URL.replace(':accountId', accountId)
}

export const listAccountsLocations = async (params = {}) => {
  const url = getUrl()
  const { data } = await axios.get(url, {
    params: {
      ...params,
      pagesize: Number.MAX_SAFE_INTEGER
    }
  })

  return data.rows
}
