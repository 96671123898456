import { ACTIONS, MUTATIONS } from '@/constants/vuex'
import { listAccountsLocations } from '@/services/accountsLocations'
import {
  updateOpeningHours,
  updateSeparationTime,
  updateStockTime,
  updateDeadlineTime
} from '@/services/accountsLocationById'
import { updateClosingTime } from '@/services/accountsClosingTime'
import { updateWorkingTimes } from '@/services/accountsWorkingDaysSettings'

export default {
  [ACTIONS.ACCOUNTS_LOCATIONS.GET_LIST]: async ({ commit }) => {
    try {
      const accounts = await listAccountsLocations()

      commit(MUTATIONS.ACCOUNTS_LOCATIONS.SET_LOCATIONS, accounts)
      return accounts
    } catch {
      commit(MUTATIONS.ACCOUNTS_LOCATIONS.SET_LOCATIONS)
      return []
    }
  },
  [ACTIONS.ACCOUNTS_LOCATIONS.UPDATE_OPENING_HOURS]: async (
    { commit },
    payload
  ) => {
    const openingHours = await updateOpeningHours({
      locationId: payload.locationId,
      ...payload
    })

    commit(MUTATIONS.ACCOUNTS_LOCATIONS.REFRESH_LOCATION, openingHours)
    commit(MUTATIONS.ACCOUNTS_LOCATIONS.SET_SELECT_LOCATION, openingHours)
    return openingHours
  },
  [ACTIONS.ACCOUNTS_LOCATIONS.UPDATE_SEPARATION_TIME]: async (
    { commit },
    payload
  ) => {
    const separationTime = await updateSeparationTime({
      locationId: payload.locationId,
      separationTime: payload.time,
      separationTimeUnit: payload.timeUnit
    })

    commit(MUTATIONS.ACCOUNTS_LOCATIONS.REFRESH_LOCATION, separationTime)
    commit(MUTATIONS.ACCOUNTS_LOCATIONS.SET_SELECT_LOCATION, separationTime)
    return separationTime
  },
  [ACTIONS.ACCOUNTS_LOCATIONS.UPDATE_STOCK_TIME]: async (
    { commit },
    payload
  ) => {
    const stockTime = await updateStockTime({
      locationId: payload.locationId,
      stockTime: payload.time,
      stockTimeUnit: payload.timeUnit
    })

    commit(MUTATIONS.ACCOUNTS_LOCATIONS.REFRESH_LOCATION, stockTime)
    commit(MUTATIONS.ACCOUNTS_LOCATIONS.SET_SELECT_LOCATION, stockTime)
    return stockTime
  },
  [ACTIONS.ACCOUNTS_LOCATIONS.UPDATE_CLOSING_TIME]: async (
    { commit },
    payload
  ) => {
    const businessHourSettings = await updateClosingTime({
      locationId: payload.locationId,
      carrierBusinessHours: payload.carrierBusinessHours
    })

    commit(MUTATIONS.ACCOUNTS_LOCATIONS.REFRESH_LOCATION, businessHourSettings)
    commit(
      MUTATIONS.ACCOUNTS_LOCATIONS.SET_SELECT_LOCATION,
      businessHourSettings
    )
    return businessHourSettings
  },
  [ACTIONS.ACCOUNTS_LOCATIONS.UPDATE_WORKING_DAYS_SETTINGS]: async (
    { commit, dispatch, state },
    workingDaysSettings
  ) => {
    const locationId = state.selectedLocation.id

    const location = await updateWorkingTimes({
      locationId,
      workingDaysSettings
    })

    await dispatch(ACTIONS.ACCOUNTS_LOCATIONS.GET_LIST)
    commit(MUTATIONS.ACCOUNTS_LOCATIONS.SET_SELECT_LOCATION, location)
  },
  [ACTIONS.ACCOUNTS_LOCATIONS.UPDATE_PICKUP_DEADLINE]: async (
    { commit },
    payload
  ) => {
    const deadlineTime = await updateDeadlineTime({
      locationId: payload.locationId,
      deadlineTime: payload.deadlineTime
    })

    commit(MUTATIONS.ACCOUNTS_LOCATIONS.REFRESH_LOCATION, deadlineTime)
    commit(MUTATIONS.ACCOUNTS_LOCATIONS.SET_SELECT_LOCATION, deadlineTime)
    return deadlineTime
  }
}
