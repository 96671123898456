import axios from '@/lib/axios'

const URL = 'accounts/location/:locationId'

const getUrl = (locationId, suffix) => {
  if (!locationId) return console.error('Location id is required')
  const url = URL.replace(':locationId', locationId)
  return `${url}/${suffix}`
}

export const updateSeparationTime = async ({ locationId, ...payload }) => {
  const url = getUrl(locationId, 'separation-time')
  const { data } = await axios.put(url, payload)

  return data
}

export const updateStockTime = async ({ locationId, ...payload }) => {
  const url = getUrl(locationId, 'stock-time')
  const { data } = await axios.put(url, payload)

  return data
}

export const updateOpeningHours = async ({ locationId, ...payload }) => {
  const url = getUrl(locationId, 'opening-hours')
  const { data } = await axios.put(url, payload.openingHours)

  return data
}

export const updateDeadlineTime = async ({ locationId, ...payload }) => {
  const url = getUrl(locationId, 'pickup-deadline')
  const { data } = await axios.put(url, payload.deadlineTime)
  return data
}
